@tailwind base;
@tailwind components;
@tailwind utilities;

.highlight-line {
    background-color: #465671;
    display: block;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="range"]::-webkit-slider-thumb {
        width: 15px;
        -webkit-appearance: none;
        appearance: none;
        height: 15px;
        cursor: ew-resize;
        background: #FFF;
        box-shadow: -405px 0 0 400px #605E5C;
        border-radius: 50%;
    }
}

h1 {
    font-size: 3rem;
    font-weight: 900;
}

h2 {
    font-size: 2.5rem;
    font-weight: 800;
}

h3 {
    font-size: 2rem;
    font-weight: 800;
}

h4 {
    font-size: 1.75rem;
    font-weight: 700;
}

h5 {
    font-size: 1.5rem;
    font-weight: 600;
}

h6 {
    font-size: 1.2rem;
    font-weight: 600;
}

ul {
    list-style: disc;
}

ol {
    list-style: upper-roman;
}